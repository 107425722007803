import React from "react"
import { Row, Col } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import InlightMediaLogo from "./Individuals/InlightMediaLogo"
import MessageFromModalButton from "./MessageFromModal/MessageFromModalButton"
import * as style from "./Supporters.module.css"

const Partners = () => {
    const data = useStaticQuery(graphql`
    {
      idahoDefenseAlliance: file(relativePath: { eq: "idaho-defense-alliance.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 162, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
        idahoVeterans: file(relativePath: { eq: "idvs-logo-header.png" }) {
        childImageSharp {
          fluid(maxWidth: 162, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sayler: file(relativePath: { eq: "Sayler.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hagedorn: file(relativePath: { eq: "Hagedorn.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      universityIdaho: file(relativePath: { eq: "university-of-idaho.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fairfieldInn: file(relativePath: { eq: "fairfield-inn.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
      <div className="tab-content">
    <Row className={`${style.supportersRow} my-4`}>
      <Col md={2} className="text-center">
        <a href="https://idahodefensealliance.com/" target="_blank" rel="noreferrer noopener">
          <Img fluid={data.idahoDefenseAlliance.childImageSharp.fluid} alt="Idaho Defense Alliance Logo" style={{maxWidth: '162px'}} />
        </a>
      </Col>
      <Col md={10}>
        <h2>Idaho Defense Alliance</h2>
        <h4>Working to enhance Idaho's economy and support the defense of our Nation.</h4>
        <MessageFromModalButton 
        messagePerson="Maj Gen Gary Sayler"
        personTitle="IDA President"
        partnerUrl="https://idahodefensealliance.com/"
        partner="Idaho Defense Alliance"
        message="The Idaho Defense Alliance advocates in support of Idaho’s military missions, installations, members and businesses in the defense sector.  In conjunction with our Idaho focus we believe in a strong national defense and therefore are privileged to support the USS Idaho Commissioning Foundation's efforts in the commissioning of the US Navy’s new attack submarine, the USS Idaho, a significant addition to the Navy’s fleet and one that will help protect our nation against foreign aggression."
        personPhoto={data.sayler.childImageSharp.fluid}
        partnerLogo={<Img fluid={data.idahoDefenseAlliance.childImageSharp.fluid} alt="Idaho Defense Alliance Logo" style={{maxWidth: '162px'}} />}
      />
      </Col>
    </Row>
    <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center">
    <a href="https://www.veterans.idaho.gov/" target="_blank" rel="noreferrer noopener">
      <Img fluid={data.idahoVeterans.childImageSharp.fluid} alt="Idaho Division of Veterans Services" />
    </a>
    </Col>
    <Col md={10}>
      <h2>Idaho Division of Veterans Services</h2>
      <h4>We are dedicated to serving Idaho’s veterans and their families by providing superior advocacy, excellent assistance with benefits and education, high quality long-term care, and respectful interment services in a dignified final resting place.</h4>
      <MessageFromModalButton 
        //messagePerson="Marv Hagedorn"
        personTitle="Chief Administrator"
        partnerUrl="https://www.veterans.idaho.gov/"
        partner="Idaho Division of Veterans Services"
        message="A message from IDVS Chief, Marv Hagedorn"
        personPhoto={data.hagedorn.childImageSharp.fluid}
        partnerLogo={<Img fluid={data.idahoVeterans.childImageSharp.fluid} alt="Idaho Division of Veterans Services" />}
      />
    </Col>
  </Row>
  <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center">
    <a href="https://inlightmedia.com" target="_blank" rel="noreferrer noopener">
     <InlightMediaLogo />
    </a>
    </Col>
    <Col md={10}>
      <h2>Inlight Media</h2>
      <h4>An Idaho company delivering unique and exceptional web experiences.</h4>
      <MessageFromModalButton 
        messagePerson="Jansen Gunderson"
        personTitle="Principal"
        partnerUrl="https://inlightmedia.com"
        partner="Inlight Media, LLC"
        message="Inlight Media has been serving non-profit organizations throughout the Intermountain West for the past 15 years. We are grateful for the opportunity to support the USS Idaho Commissioning Committee and US Navy servicemen and women."
        partnerLogo={<InlightMediaLogo />}
      />
    </Col>
  </Row>
  <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center">
    <a href="https://www.uidaho.edu/class/music" target="_blank" rel="noreferrer noopener">
    <Img fluid={data.universityIdaho.childImageSharp.fluid} alt="Idaho Division of Veterans Services" />
    </a>
    </Col>
    <Col md={10}>
      <h2>University of Idaho Lionel Hampton School of Music</h2>
      {/* <h4>An Idaho company delivering unique and exceptional web experiences.</h4> */}
      {/* <MessageFromModalButton 
        messagePerson="Jansen Gunderson"
        personTitle="Principal"
        partnerUrl="https://inlightmedia.com"
        partner="Inlight Media, LLC"
        message="Inlight Media has been serving non-profit organizations throughout the Intermountain West for the past 15 years. We are grateful for the opportunity to support the USS Idaho Commissioning Committee and US Navy servicemen and women."
        partnerLogo={<InlightMediaLogo />}
      /> */}
    </Col>
  </Row>
  <Row className={`${style.supportersRow} my-4`}>
    <Col md={2} className="text-center">
    <a href="https://www.marriott.com/en-us/hotels/puwfi-fairfield-inn-and-suites-moscow/overview/?scid=bb1a189a-fec3-4d19-a255-54ba596febe2&y_source=1_MjgxODQ5NC03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D" target="_blank" rel="noreferrer noopener">
    <Img fluid={data.fairfieldInn.childImageSharp.fluid} alt="Idaho Division of Veterans Services" />
    </a>
    </Col>
    <Col md={10}>
      <h2>Fairfield Inn &amp; Suites Moscow</h2>
      <h4>1000 West Pullman Road, Moscow, Idaho, USA, 83843</h4>
      {/* <MessageFromModalButton 
        messagePerson="Jansen Gunderson"
        personTitle="Principal"
        partnerUrl="https://inlightmedia.com"
        partner="Inlight Media, LLC"
        message="Inlight Media has been serving non-profit organizations throughout the Intermountain West for the past 15 years. We are grateful for the opportunity to support the USS Idaho Commissioning Committee and US Navy servicemen and women."
        partnerLogo={<InlightMediaLogo />}
      /> */}
    </Col>
  </Row>
  </div>
  )
}

export default Partners
