import React from "react";
import styled from "styled-components"

const Logo = styled.svg `
  width: 162px;
  height: 102px;
  
  .cls-1 {
    fill: #4078bd;
  }

`;

const InlightMediaLogo = () => {
    return (

<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 569.16846 358.12109">
<g id="Layer_2" data-name="Layer 2">
    <g id="Layer_3" data-name="Layer 3">
      <g>
        <path class="cls-1" d="M13.209,0V151.11621H2.11328V0Z"/>
        <path class="cls-1" d="M52.30908,151.11621V40.15674h7.92578L61.6084,54.31738q21.241-14.15991,41.63623-14.16064,40.4729,0,40.47363,34.97851v75.981h-11.0957V74.81836q0-24.728-29.90625-24.728-19.97314,0-39.31152,14.05469v86.97119Z"/>
        <path class="cls-1" d="M190.74414,0V151.11621H179.648V0Z"/>
        <path class="cls-1" d="M240.93994,0V12.68115H227.73047V0ZM239.8833,40.15674V151.11621H228.78711V40.15674Z"/>
        <path class="cls-1" d="M367.43359,151.11621q0,40.15577-48.92773,40.15625-20.50269,0-36.35254-5.2832v-9.51074q16.16821,5.28222,36.564,5.2832,37.61939,0,37.62011-30.64551V145.832q-18.81078,5.28663-33.18164,5.28418-52.62671,0-52.62646-56.11328,0-54.846,56.53613-54.84619a172.48911,172.48911,0,0,1,40.36816,4.54443ZM356.3374,52.62646a184.23466,184.23466,0,0,0-30.11719-2.53613q-44.91357,0-44.9121,44.70069,0,45.75879,41.95312,45.75781,15.21753,0,33.07617-5.707Z"/>
        <path class="cls-1" d="M401.25,151.11621V0h11.0957V52.2041q19.44288-12.04761,39.83985-12.04736,40.4729,0,40.47363,41.31933v69.64014h-11.0957V76.93213q0-26.84106-29.90625-26.8418-19.97315,0-39.31153,14.05469v86.97119Z"/>
        <path class="cls-1" d="M526.05273,22.19189h7.81983l2.00781,17.96485h33.28809v9.51074H536.83154v70.063q0,21.87452,16.063,21.875h16.27393v9.51074h-16.063q-27.05347,0-27.05274-29.90625Z"/>
        <path class="cls-1" d="M0,358.12109V207.00488H11.0957L79.25684,345.12305l63.93357-138.11817h10.03908V358.12109H143.19041V227.40039L83.167,358.12109H74.07812L10.03906,226.34375V358.12109Z"/>
        <path class="cls-1" d="M231.74609,247.16162q45.54639,0,45.54688,50.40772,0,3.59325-.21192,7.50293H192.8584q0,43.53809,46.07422,43.53808,18.91553,0,33.60449-5.28418v9.51074q-14.68945,5.28663-33.60449,5.28418-57.17139,0-57.1709-56.748Q181.76172,247.16211,231.74609,247.16162Zm-38.88769,47.977h73.76074q-.63428-38.67772-34.87305-38.67724Q194.75879,256.46143,192.8584,295.13867Z"/>
        <path class="cls-1" d="M397.7627,351.46387q-18.07032,6.65771-45.5459,6.65722-51.6753,0-51.67578-57.69921,0-53.26026,52.627-53.26026a112.56689,112.56689,0,0,1,33.499,5.28369V207.00488H397.7627Zm-11.09571-89.085a99.024,99.024,0,0,0-33.39355-5.70655q-40.897,0-40.89649,43.01026,0,48.50536,40.89649,48.50488a133.87339,133.87339,0,0,0,33.39355-4.12109Z"/>
        <path class="cls-1" d="M445.84473,207.00488V219.686h-13.209V207.00488Zm-1.05664,40.15674V358.12109H433.69238V247.16162Z"/>
        <path class="cls-1" d="M474.377,326.73535q0-33.0747,43.96094-33.07617a280.01449,280.01449,0,0,1,33.18262,2.11328V282.14014q0-25.46557-32.01953-25.46778a127.51485,127.51485,0,0,0-36.1416,5.28418v-9.51123a127.51418,127.51418,0,0,1,36.1416-5.28369q43.11474,0,43.11523,34.4502v76.50927h-6.76367l-2.53613-10.99023q-20.81984,10.99218-40.36817,10.99023Q474.376,358.12109,474.377,326.73535Zm43.96094-23.56543q-32.8667,0-32.86523,23.24805,0,22.19238,27.47558,22.19238a74.78432,74.78432,0,0,0,38.57227-10.7793V305.2832A280.01449,280.01449,0,0,0,518.33789,303.16992Z"/>
      </g>
    </g>
  </g>
</Logo>
    )
}

export default InlightMediaLogo

